<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{
                                $get(
                                    getNewsletterDetails,
                                    'email',
                                    $t('global:empty')
                                )
                            }}
                        </p>
                        <span
                            ><b>{{ `${$t('newsletter:createdAt')}` }}:</b>
                            {{
                                getNewsletterDetails.createdAt
                                    ? $moment(
                                          getNewsletterDetails.createdAt
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="$get(getNewsletterDetails, 'email', '')"
                            :label="$t('newsletter:email')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3"
                        ><v-text-field
                            readonly
                            :value="
                                getNewsletterDetails.createdAt
                                    ? $moment(
                                          getNewsletterDetails.createdAt
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            "
                            :label="$t('newsletter:createdAt')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            readonly
                            :value="getNewsletterDetails.newsletterKind"
                            :label="$t('newsletter:source')"
                            :items="[getNewsletterDetails.newsletterKind]"
                            return-object
                            item-value="field"
                            append-icon=""
                            ><template v-slot:item="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                            <template v-slot:selection="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : 'white'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col>
                        <v-textarea
                            :value="getNewsletterDetails.description"
                            :label="`${$t('newsletter:description')}`"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getNewsletterDetails']),
    },
    methods: {},
}
</script>
