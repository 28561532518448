var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$get( _vm.getNewsletterDetails, 'email', _vm.$t('global:empty') ))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('newsletter:createdAt')}`)+":")]),_vm._v(" "+_vm._s(_vm.getNewsletterDetails.createdAt ? _vm.$moment( _vm.getNewsletterDetails.createdAt ).format(`DD/MM/YYYY`) : _vm.$t('global:empty')))])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getNewsletterDetails, 'email', ''),"label":_vm.$t('newsletter:email')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getNewsletterDetails.createdAt
                                ? _vm.$moment(
                                      _vm.getNewsletterDetails.createdAt
                                  ).format(`DD/MM/YYYY`)
                                : _vm.$t('global:empty'),"label":_vm.$t('newsletter:createdAt')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"readonly":"","value":_vm.getNewsletterDetails.newsletterKind,"label":_vm.$t('newsletter:source'),"items":[_vm.getNewsletterDetails.newsletterKind],"return-object":"","item-value":"field","append-icon":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : 'white'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}])})],1)],1),_c('v-row')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }